import api from "../api";
import { AxiosResponse } from "axios";
import qs from "qs";
import type { Locale } from "../../interfaces/Locale";
import type { MedicalAction } from "../../interfaces/Services";

class MedicalActionAPI {
  list = (locale: Locale): Promise<AxiosResponse<MedicalAction[]>> => {
    return api.get(`v2/medical-actions/`, {
      params: { country: locale },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });
  };
}

const medicalActionAPI = new MedicalActionAPI();
export default medicalActionAPI;
