import { AxiosResponse } from "axios";
import api from "../api";
import qs from "qs";
import { ServiceFeePayload } from "../../interfaces/Payments";

class PaymentAPI {
  list = (ids?: string[]): Promise<AxiosResponse<any>> => {
    return api.get(`v2/payments/`, {
      params: { id__in: ids },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });
  };
  getServiceFee = (data: ServiceFeePayload): Promise<AxiosResponse<any>> => {
    return api.post(`v2/service-fee`, data)
  }
}

const paymentAPI= new PaymentAPI();
export default paymentAPI;
