import { AxiosResponse } from "axios";
import api from "../api";
import qs from "qs";

class GroupedMedicalOrderAPI {
  post = (
    triggeredFrom: string,
    appointmentPatient: string
  ): Promise<AxiosResponse<any>> => {
    return api.post(`v2/grouped-medical-orders/`, {
      triggered_from: triggeredFrom,
      appointment_patient: appointmentPatient,
    });
  };
}

const groupedMedicalOrderAPI = new GroupedMedicalOrderAPI();
export default groupedMedicalOrderAPI;
