import { AxiosResponse } from "axios";
import api from "../api";
import qs from "qs";

class AppointmentPatientAPI {
  list = (ids: string[]): Promise<AxiosResponse<any>> => {
    return api.get(`v2/appointment-patients/`, {
      params: { id__in: ids },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });
  };
  patch = (id: string, data: any): Promise<AxiosResponse<any>> => {
    return api.patch(`v2/appointment-patients/${id}/`, data);
  };
  deleteLabService = (
    id: string,
    labServiceId: string
  ): Promise<AxiosResponse<any>> => {
    return api.delete(`v2/appointment-patients/${id}/lab-service/`, {
      data: { lab_service_id: labServiceId },
    });
  };
  checkSubservices = (id: string, lab_service_id: string): Promise<AxiosResponse<any>> => {
    return api.get(`v2/appointment-patients/${id}/check-subservices/`, {
      params: { lab_service_id },
    });
  };
  addPack = (data): Promise<AxiosResponse<any>> => {
    return api.post(`/v2/appointment-patient-item-pack/`, data);
  };
  deletePack = (
    id: string,
    apptPatientPackId: string
  ): Promise<AxiosResponse<any>> => {
    return api.delete(`v2/appointment-patients/${id}/pack/`, {
      data: { appt_patient_pack_id: apptPatientPackId },
    });
  };
}

const appointmentPatientAPI = new AppointmentPatientAPI();
export default appointmentPatientAPI;
