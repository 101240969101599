import { AxiosResponse } from "axios";
import api from "../api";
import qs from "qs";
import { Locale } from "../../interfaces/Locale";

class TerritoryAPI {
  list = (locale: Locale): Promise<AxiosResponse<any>> => {
    return api.get(`v2/territories/`, {
      params: { country: locale },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });
  };
}

const territoryAPI = new TerritoryAPI();
export default territoryAPI;
