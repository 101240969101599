import { AxiosResponse } from "axios";
import api from "../api";

class ResultAPI {
  list = (
    appointment: string,
    patient: string
  ): Promise<AxiosResponse<any>> => {
    return api.get(`v2/results/`, {
      params: { appointment, patient },
    });
  };
}

const resultAPI = new ResultAPI();
export default resultAPI;
