import api from "../api";
import { AxiosResponse } from "axios";
import qs from "qs";

class ReceiverAPI {
  list = (params?: any): Promise<AxiosResponse<any>> => {
    return api.get(`v2/receivers/`, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });
  };

  get = (id: string): Promise<AxiosResponse<any>> => {
    return api.get(`v2/receivers/${id}/`);
  };

  listLabs = (id: string): Promise<AxiosResponse<any>> => {
    return api.get(`v2/receivers/${id}/labs/`);
  };

  addLabs = (id: string, data: any): Promise<AxiosResponse<any>> => {
    return api.post(`v2/receivers/${id}/labs/`, data);
  };

  removeLabs = (id: string, data: any): Promise<AxiosResponse<any>> => {
    return api.delete(`v2/receivers/${id}/labs/`, {
      data,
    });
  };
  create = (data: any): Promise<AxiosResponse<any>> => {
    return api.post(`v2/receivers/`, data);
  };

  update = (id: string, data: any): Promise<AxiosResponse<any>> => {
    return api.patch(`v2/receivers/${id}/`, data);
  };
}

const receiverAPI = new ReceiverAPI();
export default receiverAPI;
