import api from "../api";
import { AxiosResponse } from "axios";
import qs from "qs";

class PackAPI {
  create(data: Object): Promise<AxiosResponse> {
    return api.post("v2/packs/", (data = data));
  }

  list(data?: Object): Promise<AxiosResponse> {
    return api.get(`v2/packs/?${qs.stringify(data)}`);
  }

  listCategories(data?: Object): Promise<AxiosResponse> {
    return api.get(`v2/packs/categories/?${qs.stringify(data)}`);
  }
}

const packAPI = new PackAPI();

export default packAPI;
