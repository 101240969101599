import { AxiosResponse } from "axios";

import api from "../api";
import qs from "qs";
import { GetSearchServicePayload } from "../../interfaces/Services";
import { CartItemObjectType } from "../../interfaces/cartItem";
import { parseItems } from "../../parser/services";
import { RequestResponse } from "../../interfaces/request";


interface SearchParams {
  query: string;
  countryCode?: "cl" | "mx";
  commune?: string;
  category?: string;
  source?: string;
  showAll?: boolean; 
}

class ElasticSearchAPI {
  search = ({
    query,
    countryCode,
    commune,
    category,
    source,
    showAll = true,
  }: SearchParams): Promise<AxiosResponse<any>> => {
    const country = countryCode === "cl" ? "Chile" : "México";
    return api.get(
      `v2/search/?${qs.stringify({
        query,
        commune,
        country,
        category: category !== "all" ? category : null,
        source,
        show_all: showAll,
      }, { addQueryPrefix: false, skipNulls: true })}`
    );
  };

  newSearch = async ({
    query,
    countryCode,
    commune,
    category,
    source,
    showAll = true,
  }: SearchParams): Promise<RequestResponse<CartItemObjectType[]>> => {
    const res = await api.get<GetSearchServicePayload>(
      `v2/search/?${qs.stringify({
        query,
        commune,
        country: countryCode,
        category: category !== "all" ? category : null,
        source,
        show_all: showAll,
      }, { addQueryPrefix: false, skipNulls: true })}`
    );
    if (!res.data) return { data: [] };
    if (res.data.data) {
      const results = res.data.data;
      const data = parseItems(results.filter(({ price }) => price));
      return { data };
    }
    return { data: [] };
  }
}

const elasticSearchAPI = new ElasticSearchAPI();
export default elasticSearchAPI;
