import { AxiosResponse } from "axios";
import api from "../api";
import qs from "qs";

class MedicalOrderAPI {
  list = (
    groups: string[],
    appointmentPatient: string
  ): Promise<AxiosResponse<any>> => {
    return api.get(`v2/medical-orders/`, {
      params: {
        group__in: groups,
        group__appointment_patient: appointmentPatient,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });
  };

  post = (
    attachment: File,
    group: string,
    triggeredFrom: string
  ): Promise<AxiosResponse<any>> => {
    const formData = new FormData();
    formData.append("attachment", attachment);
    formData.append("group", group);
    formData.append("triggered_from", triggeredFrom);
    return api.post(`v2/medical-orders/`, formData);
  };
}

const medicalOrderAPI = new MedicalOrderAPI();
export default medicalOrderAPI;
