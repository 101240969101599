import { AxiosResponse } from "axios";
import api from "../api";
import qs from "qs";
import { Locale } from "../../interfaces/Locale";

class NurseAPI {
  listTerritories = (
    nurseId: string,
    locale: Locale
  ): Promise<AxiosResponse<any>> => {
    return api.get(`v2/nurses/${nurseId}/territories/`, {
      params: { country: locale },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });
  };

  listMedicalServices = (nurseId: string): Promise<AxiosResponse<any>> => {
    return api.get(`v2/nurses/${nurseId}/medical-services/`);
  };
  listMedicalActions = (nurseId: string): Promise<AxiosResponse<any>> => {
    return api.get(`v2/nurses/${nurseId}/medical-actions/`);
  };
  deleteMedicalActions = (
    nurseId: string,
    medicalActionIds: string[]
  ): Promise<AxiosResponse<any>> => {
    return api.delete(`v2/nurses/${nurseId}/medical-actions/`, {
      data: medicalActionIds,
    });
  };

  deleteMedicalServices = (
    nurseId: string,
    medicalServiceIds: string[]
  ): Promise<AxiosResponse<any>> => {
    return api.delete(`v2/nurses/${nurseId}/medical-services/`, {
      data: medicalServiceIds,
    });
  };

  addMedicalActions = (
    nurseId: string,
    medicalActionIds: string[]
  ): Promise<AxiosResponse<any>> => {
    return api.post(`v2/nurses/${nurseId}/medical-actions/`, medicalActionIds);
  };

  addMedicalServices = (
    nurseId: string,
    medicalServiceIds: string[]
  ): Promise<AxiosResponse<any>> => {
    return api.post(
      `v2/nurses/${nurseId}/medical-services/`,
      medicalServiceIds
    );
  };

  listActionReceivers = (nurseId: string): Promise<AxiosResponse<any>> => {
    return api.get(`v2/nurses/${nurseId}/action-receivers/`);
  };

  addActionReceiver = ({
    nurseId,
    receiverId,
    medicalActionId,
  }): Promise<AxiosResponse<any>> => {
    return api.post(`v2/nurses/${nurseId}/action-receivers/`, {
      receiver_id: receiverId,
      medical_action_id: medicalActionId,
    });
  };
  deleteActionReceiver = ({
    nurseId,
    receiverId,
    medicalActionId,
  }): Promise<AxiosResponse<any>> => {
    return api.delete(`v2/nurses/${nurseId}/action-receivers/`, {
      data: {
        receiver_id: receiverId,
        medical_action_id: medicalActionId,
      },
    });
  };
}

const nurseAPI = new NurseAPI();
export default nurseAPI;
