import api from "../api";
import { AxiosResponse } from "axios";
import qs from "qs";

class LabAPI {
  list = (params?: any): Promise<AxiosResponse<any>> => {
    return api.get(`v2/labs/`, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });
  };

  get = (id: string): Promise<AxiosResponse<any>> => {
    return api.get(`v2/labs/${id}/`);
  };
}

const labAPI = new LabAPI();
export default labAPI;
