import { AxiosResponse } from "axios";
import api from "../api";
import qs from "qs";
import { Locale } from "../../interfaces/Locale";
import { IDoctorPayload } from "../../interfaces/Funnel";

class DoctorAPI {
  list = (locale: Locale): Promise<AxiosResponse<IDoctorPayload[]>> => {
    return api.get(`v2/doctors/`, {
      params: { country: locale },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });
  };
}

const doctorAPI = new DoctorAPI();
export default doctorAPI;
