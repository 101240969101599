import { AxiosResponse } from "axios";
import api from "../api";
import qs from "qs";

class ReceiptAPI {
  list = (ids?: string[]): Promise<AxiosResponse<any>> => {
    return api.get(`v2/receipts/`, {
      params: { id__in: ids },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });
  };

  delete = (id: string): Promise<AxiosResponse<any>> => {
    return api.delete(`v2/receipts/${id}/`);
  };

  get = (id: string): Promise<AxiosResponse<any>> => {
    return api.get(`v2/receipts/${id}/`);
  };
}

const receiptAPI = new ReceiptAPI();
export default receiptAPI;
