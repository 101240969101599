import { AxiosResponse } from "axios";
import api from "../api";
import qs from "qs";

class AppointmentAPI {
  get = (id: string): Promise<AxiosResponse<any>> => {
    return api.get(`v2/appointments/${id}/`);
  };

  patch = (id: string, data: any): Promise<AxiosResponse<any>> => {
    return api.patch(`v2/appointments/${id}/`, data);
  };

  listComments = (
    id: string
  ): Promise<AxiosResponse<any>> => {
    return api.get(`v2/appointments/${id}/comments/`);
  }

  createComment = (
    id: string,
    data: Object
  ): Promise<AxiosResponse<any>> => {
    return api.post(`v2/appointments/${id}/comments/`, data);
  }

  getInsuranceComunes = (
    insurance : string
  ) : Promise<AxiosResponse<any>> => {
    return api.get(`/v2/communes/?${qs.stringify({ insurances: insurance }, { addQueryPrefix: false, arrayFormat: "repeat" })}`);
  }

  appointmentPatients = (
    id: string
  ) : Promise<AxiosResponse<any>> => {
    return api.get(`v2/appointments/${id}/appointment-patients/`);
  }

  sendOMReady = (
    data: Object
  ) : any => {
    return api.post(`v2/om-ready/`, data);
  }
}

const appointmentAPI= new AppointmentAPI();
export default appointmentAPI;
