import { AxiosResponse } from "axios";
import api from "../api";

class AppointmentCommentAPI {
  delete(id: string): Promise<AxiosResponse<any>> {
    return api.delete(`v2/appointment-comments/${id}/`);
  }
}

const appointmentCommentAPI= new AppointmentCommentAPI();
export default appointmentCommentAPI;
